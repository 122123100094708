import rooftopImg from '../../../../assets/imgs/home/001_postBar/postBar.jpg';
import Constants from '../../../../utils/Constants';
import ScrollAnimation from '../../../../utils/ScrollAnimation';

const PostBar = () => {
  return (
    <div id={Constants.POST_BAR} className='postBar'>
      <div className='container'>
        <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut' duration={100}>
          <ScrollAnimation
            ScrollAnimation
            animateIn='fadeInUp'
            duration={500}
            delay={100}
          >
            <h2 className='text_center'>
              THE DESTINATION HOSPITALITY LEADER CORNARO HOTEL PROUDLY
              PRESENTING LATESTS “HOTEL STORY”, POST BAR by Cornaro Hotel,
              opening April 2025.
            </h2>
          </ScrollAnimation>
          <div className='postBar_container'>
            <ScrollAnimation animateIn='fadeIn' duration={1000} delay={150}>
              <div className='postBar_container_img'>
                <img src={rooftopImg} alt='rooftop bar' />
              </div>
            </ScrollAnimation>
            <ScrollAnimation animateIn='fadeInUp' duration={1000} delay={200}>
              <div className='postBar_container_text'>
                <div>
                  <h2 className='title_underline_light_small'>
                    POST BAR BY CORNARO HOTEL
                  </h2>
                  <p>
                    ...is a modern, elegant lobby bar where the lines blur
                    between work, fun, and culture, as well as events with an
                    authentic vibrant atmosphere that keeps guests and locals.
                  </p>
                  <p>
                    Mix and mingle in this fancy gathering place, serving
                    upscale appetizers, small plates, and refined beverages.
                  </p>
                  <p>
                    The space itself is a celebration of color and creativity,
                    with characterful and cozy interiors.
                  </p>
                  <p>
                    Cocktails are handcrafted and the menu is share-worthy,
                    tasty, and with a local signature, simplicity is key, with a
                    focus on quality, creating and delivering exceptional guest
                    experiences.
                  </p>
                </div>
              </div>
            </ScrollAnimation>
          </div>
        </ScrollAnimation>
      </div>
    </div>
  );
};

export default PostBar;
